import React from 'react';
import { graphql } from 'gatsby';

import Markdown from 'markdown-to-jsx';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Description from '../../components/paragraphs/descriptionEnfants';
import Headings from '../../components/paragraphs/headings';


const Stages = ({
  title,
  content,
  color,
  date_debut,
  date_fin,
  details_tarif,
}) => {
  const dStyle = {
    border: '5px',
    borderColor: color,
    borderRadius: '5px',
    borderStyle: 'solid',
    boxShadow: '1px 3px 2px #888888',
    padding: '0.6rem',
    marginTop: '10px',
    marginBottom: '10px',
  };
  const tStyle = {
    border: '5px',
    borderColor: color,
    backgroundColor: color,
    borderRadius: '5px',
    borderStyle: 'solid',
    boxShadow: '1px 3px 2px #888888',
    padding: '0.6rem',
    marginTop: '20px',
    marginBottom: '10px',
    textAlign: 'center',
  };
  const hStyle = {
    padding: '0.5rem',
  };
  const pStyle = {
    paddingTop: '0.5rem',
    paddingLeft: '0.5rem',
  };
  return (
    <div
      style={{
        width: '100%',
      }}
    >
      <div style={tStyle}>
        <h3>{title}</h3>
      </div>
      <div style={dStyle}>
        <h4 style={hStyle}>
          {`Du ${getDay(date_debut)} au  ${getDayWithYear(
            date_fin,
          )}`}

        </h4>
        <span style={pStyle}>
          <Markdown>{content}</Markdown>
        </span>
        <span style={pStyle}>
          <Markdown>{details_tarif}</Markdown>
        </span>
      </div>
    </div>
  );
};

export default function Template({ data, pageContext }) {
  const { page } = data;
  const title = `${page.nom}`;
  const { image_presentation } = page;
  const imgDesc = page.img_desc;
  const { icon } = page;
  const paragraphs = [
    {
      key: 'Horaires_et_Tarifs',
      name: 'Horaires et Tarifs',
      color: page.couleur.valeur,
    },
    {
      key: 'description_des_ateliers',
      name: 'Description des ateliers',
      color: page.couleur.valeur,
    },
    {
      key: 'stages_vacances_scolaires',
      name: 'Stages vacances scolaires',
      color: page.couleur.valeur,
    },
  ];
  const tStyle = {
    border: '5px',
    borderColor: page.couleur.valeur,
    borderRadius: '5px',
    borderStyle: 'solid',
    boxShadow: '1px 3px 2px #888888',
    padding: '0.6rem',
    marginTop: '20px',
    marginBottom: '10px',
    textAlign: 'center',
  };

  const dayList = page.jours.map((day) => day.jour);
  const stageList = data.stages.nodes;
  return (


    <Layout title={page.meta.title} buildTime={pageContext.buildTime}>
      <SEO meta={page.meta} />
      <div className="p-1">
        <Headings
          title={title}
          imgPresentation={image_presentation}
          icon={icon}
          color={page.couleur.valeur}
          paragraphs={paragraphs}
          dayList={dayList}
        />

        <Description
          id={page.id}
          title={title}
          description={page.description}
          imgDesc={imgDesc}
          days={page.jours}
          ateliers={page.description_des_ateliers}
          color={page.couleur.valeur}
          anchor="description_des_ateliers"
          detailsTarif={page.details_tarif}
          stageList={stageList}
        />
      </div>

    </Layout>
  );
}

const getDay = (date) => {
  const objTime = new Date(`${date}T12:00:00`);
  const options = {
    weekday: 'long',

    month: 'long',
    day: 'numeric',
  };
  const currentTime = objTime.toLocaleDateString('fr-FR', options);
  return currentTime;
};

const getDayWithYear = (date) => {
  const objTime = new Date(`${date}T12:00:00`);
  const options = {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  };
  const currentTime = objTime.toLocaleDateString('fr-FR', options);
  return currentTime;
};

export const pageQuery = graphql`
  query coursEnfantsQuery {
    site: site {
      siteMetadata {
        title
        description
        author
      }
    }
    page: strapiCoursEnfants {
      id
      nom
      description
      nota_bene
      details_tarif
      couleur {
        valeur
      }
      icon {
        publicURL
        childImageSharp {
          fluid(maxWidth: 600, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      img_desc {
        nom
        image {
          publicURL
          childImageSharp {
            fluid(maxWidth: 1200, quality: 100) {
              ...GatsbyImageSharpFluid
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
      }
      description_des_ateliers {
        contenu
        id
        nom
        images {
          image {
            publicURL
            childImageSharp {
              fluid(maxWidth: 600, quality: 100) {
                ...GatsbyImageSharpFluid
                ...GatsbyImageSharpFluidLimitPresentationSize
              }
            }
          }
          nom
        }
      }
      image_presentation {
        publicURL
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      images {
        image {
          publicURL
          childImageSharp {
            fluid(maxWidth: 600, quality: 100) {
              ...GatsbyImageSharpFluid
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
        nom
      }
      jours {
        jour
        horaires {
          description
          heure_debut
          heure_fin
          nom
          artiste {
            id
            nom
          }
          tarif {
            nom
            id
            tarifs {
              id
              nom
              valeur
            }
          }
        }
      }
      meta {
        description
        title
        url
        image {
          publicURL
          childImageSharp {
            fixed(width: 200) {
            width
            height
            src
          }
          }
        }
      }
    }
    stages: allStrapiStagesEnfants(filter: { actif: { eq: true } }) {
      nodes {
        contenu
        createdAt
        date_publication
        debut
        details_tarif
        fin
        nom
        id
      }
    }
  }
`;
