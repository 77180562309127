import PropTypes from 'prop-types';
import React from 'react';
import Image from 'gatsby-image';
import Markdown from 'markdown-to-jsx';
import urlSlug from 'url-slug';
import ImageGallery from '../page/ImageGallery';
import Planner from '../page/plannerEnfants';
import CoursCards from './coursCards';

const Stages = ({
  title,
  content,
  color,
  date_debut,
  date_fin,
  details_tarif,
}) => {
  const dStyle = {
    border: '5px',
    borderColor: color,
    borderRadius: '5px',
    borderStyle: 'solid',
    boxShadow: '1px 3px 2px #888888',
    padding: '0.6rem',
    marginTop: '10px',
    marginBottom: '10px',
  };
  const tStyle = {
    border: '3px',
    borderColor: color,
    backgroundColor: color,
    borderRadius: '5px',
    borderStyle: 'solid',
    boxShadow: '1px 3px 2px #888888',
    padding: '2px',
    marginTop: '10px',
    marginBottom: '10px',
    textAlign: 'center',
  };
  const hStyle = {
    padding: '0.5rem',
  };

  return (
    <div
      style={{
        width: '100%',
      }}
      className="col-md-6"
    >
      <div style={tStyle}>
        <h4>{title}</h4>
      </div>
      <div style={dStyle}>
        <h4 style={hStyle}>
          {`Du ${getDay(date_debut)} au  ${getDayWithYear(
            date_fin,
          )}`}

        </h4>
        <span>
          <Markdown>{content}</Markdown>
        </span>
        <span>
          <Markdown>{details_tarif}</Markdown>
        </span>
      </div>
    </div>
  );
};


const getDay = (date) => {
  const objTime = new Date(`${date}T12:00:00`);
  const options = {
    weekday: 'long',

    month: 'long',
    day: 'numeric',
  };
  const currentTime = objTime.toLocaleDateString('fr-FR', options);
  return currentTime;
};

const getDayWithYear = (date) => {
  const objTime = new Date(`${date}T12:00:00`);
  const options = {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  };
  const currentTime = objTime.toLocaleDateString('fr-FR', options);
  return currentTime;
};


const Atelier = ({
  key, id, title, content, seances, color, images, detailsTarif, stageList,
}) => {
  const tStyle = {
    WebkitTextDecoration: `${color} solid underline`,
    textDecoration: `${color} solid underline`,
    WebkitTextUnderlinePosition: 'under',
    textUnderlinePosition: 'under',
    fontWeight: 'bold',
    padding: '10px',
  };
  const aStyle = {
    marginTop: '10px',
    padding: '10px',
  };
  const cStyle = {
    padding: '10px',
    textAlign: 'justify',
  };
  const anchorStyle = {
    display: 'block',
    position: 'relative',
    top: '-50px',
    visibility: 'hidden',
  };

  if (id.indexOf('stage') === -1) {
    // console.log('--> images', images);
    const tarifs = getTarifs(seances);
    return (
      <div key={key} style={aStyle}>
        <a style={anchorStyle} id={id} />
        <h3 style={tStyle}>{title}</h3>
        <div style={cStyle}>
          <Markdown>{content}</Markdown>
        </div>
        <Planner tarifs={tarifs} color={color} detailsTarif={detailsTarif} seances={seances} />
        <div className="d-flex flex-wrap">
          <ImageGallery images={images} />
        </div>
      </div>
    );
  }
  return (
    <div key={key} style={aStyle}>
      <a style={anchorStyle} id={id} />
      <h3 style={tStyle}>{title}</h3>
      <div style={cStyle}>
        <Markdown>{content}</Markdown>
      </div>
      {stageList.map((stage, index) => (
        <div key={`sk_${index}`} style={{ width: '100%' }}>
          <Stages
            title={stage.nom}
            content={stage.contenu}
            color={color}
            date_debut={stage.debut}
            date_fin={stage.fin}
            details_tarif={stage.details_tarif}
          />
        </div>
      ))}
      <div className="d-flex flex-wrap">
        <ImageGallery images={images} />
      </div>
    </div>
  );
};
function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}
const getTarifs = (seances) => {
  try {
    const tarifs = seances.reduce((acc, currentSeance) => {
      if (!acc.hasOwnProperty(currentSeance.tarif.id) && currentSeance.tarif.id !== undefined) {
        acc.push(currentSeance.tarif.id);
      }
      return acc;
    }, []).filter(onlyUnique);
    return tarifs;
  } catch (error) {
    console.log(error);
  }
};

const Description = ({
  id, title, description, days, anchor, ateliers, color, imgDesc, detailsTarif, stageList,
}) => {
  const dStyle = {
    textAlign: 'justify',
    border: '5px',
    borderColor: color,
    backgroundColor: color,
    borderRadius: '5px',
    borderStyle: 'solid',
    boxShadow: '1px 3px 2px #888888',
    marginBottom: '1rem',
    marginTop: '10px',
  };
  const aStyle = {

    textAlign: 'justify',
    border: '5px',
    borderColor: color,
    borderRadius: '5px',
    borderStyle: 'solid',
    boxShadow: '1px 3px 2px #888888',
    marginBottom: '1rem',
  };

  const cStyle = {

    textAlign: 'justify',
    border: '5px',
    borderColor: 'white',
    borderRadius: '5px',
    marginBottom: '1rem',
    marginTop: '10px',
  };
  return (
    <div>
      <div className="row">
        <div style={dStyle} className="col-lg-6 col-12">
          <div>

            <a className="anchor" id={anchor} />
            <button className="link-button" />
            <h2
              className=" text-center "
              style={{ fontWeight: 'bold', fontSize: '1.5rem' }}
            >
              {title}
            </h2>
          </div>
          <div><Markdown>{description}</Markdown></div>
        </div>
        <div style={cStyle} className="col-lg-6 col-12 container">
          <CoursCards id={id} />
        </div>
      </div>


      <div style={aStyle} className="row">
        {ateliers.map((item) => {
          const anchor = urlSlug(item.nom);

          const seances = getSeances(item, days);

          return (
            <div key={`a_${item.id}`}>
              <Atelier
                title={item.nom}
                content={item.contenu}
                seances={seances}
                images={item.images}
                color={color}
                id={anchor}
                detailsTarif={detailsTarif}
                stageList={stageList}
              />
            </div>
          );
        })}
      </div>

    </div>
  );
};


export default Description;


const getSeances = (atelier, days) => {
  try {
    const seances = days.reduce((acc, currentDay) => {
      currentDay.horaires.forEach((seance) => {
        seance.jour = currentDay.jour;
        acc.push(seance);
      });

      return acc;
    }, []);

    return seances;
  } catch (error) {
    console.log(error);
  }
};


Description.propTypes = {
  title: PropTypes.string,
  details: PropTypes.string,
  anchor: PropTypes.string,
  ateliers: PropTypes.arrayOf(
    PropTypes.shape({
      nom: PropTypes.string,
      contenu: PropTypes.string,
      id: PropTypes.string,
      key: PropTypes.string,
      images: PropTypes.arrayOf(
        PropTypes.shape({
          nom: PropTypes.string,
          image: PropTypes.shape({
            publicURL: PropTypes.string,
          }),
        }),
      ),
    }),
  ),
  color: PropTypes.string,
  imgDesc: PropTypes.shape({
    nom: PropTypes.string,
    image: PropTypes.shape({
      publicURL: PropTypes.string,
    }),
  }),
};

Description.defaultProps = {
  title: '',
  details: '',
  anchor: '',
  ateliers: [],
  color: '',
  imgDesc: PropTypes.shape({
    nom: '',
    image: PropTypes.shape({
      publicURL: '',
    }),
  }),
};
